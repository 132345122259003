<template>
  <div class="about">
    <TitleHeader msg="账号信息"></TitleHeader>
    <el-table
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="memberStoreList"
        class="wraper "
        style="width: 100%;"
        :header-cell-style="tableHeaderColor"
      >
      <el-table-column
        align='center'
        label="会员名称"
        width="180"
      >
       <template  slot-scope="scope">
         <span class="NowBtn" v-if="scope.row.id===NowMemberStoreId">
              <el-tag > {{scope.row.enterpriseName}}</el-tag>
           </span>
            <span v-else>{{scope.row.enterpriseName}}</span>
        </template>
      </el-table-column>

      <el-table-column
       align='center'
        label="公司地址"
      >
      <template slot-scope="scope">
            {{scope.row.addressProvinceString}}{{scope.row.addressCityString}}{{scope.row.addressAreaString}}{{scope.row.address}}
        </template>
      </el-table-column>
      <el-table-column
        align='center'
        prop="linkman"
        label="姓名"
        width="80"
      >
      </el-table-column>

      <el-table-column
        align='center'
        prop="mobile"
        width="120"
        label="联系电话"
      >
      </el-table-column>
      
      <!--   prop="disableStatusStr"  -->
      <el-table-column
        align='center'
        width="100"
        label="当前状态"
      >
       <template slot-scope="scope">
            <span class="lockBtn Btnbox" v-if="scope.row.disableStatus==1">锁定</span>
            <span class="certifiedBtn Btnbox" v-else-if="scope.row.auditStatus==4">正常</span>
            <span class="pastBtn Btnbox" v-else-if="scope.row.auditStatus==6">资质过期</span>
            <span class="unauthorizedBtn Btnbox" v-else>未认证</span>
        </template>
      </el-table-column>
      <!-- <el-table-column  align='center' prop="email" label="电子邮箱">
      </el-table-column> -->
       <el-table-column  width="100"  align='center'  label="操作">
         <template slot-scope="scope" >
            <el-button  v-if="scope.row.id!==NowMemberStoreId" type="text" size="small" @click.native="switchFun(scope.row)">切换</el-button>
            <!--  auditStatus  {{scope.row.auditStatus}}-->
            <el-button  v-if="scope.row.id!==NowMemberStoreId" type="text" size="small" @click.native="delMerberFun(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import {MembertDetail} from 'api/member.js';
import { GetMemberStore,delMember } from "api/member.js";
import { changeMemberStore,RefreshLogin } from "api/home.js";
import { setToken,setLocalToken} from "utils/session.js";
import Cookies from 'js-cookie';
import Qs from "qs";
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
   inject:['reload'],
  name: "MyInvoice",
  data() {
    return {
        //加载loading条
      loading: false,
      memberStoreList: [],
      NowMemberStoreId:''
    };
  },
  methods: {
    // 修改table header的背景色
    tableHeaderColor({rowIndex }) {
      if (rowIndex === 0) {
        return 'background-color:#f6f6f6;color: #000;font-size:14px;'
      }
    },
 
    // 获取
    getMembertDetail: function() {
        this.NowMemberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
      // const parmes=Number(Qs.parse(sessionStorage.getItem("memberData")).memberId);
      // 获取所有门店数据
      GetMemberStore().then((res) => {
        this.memberStoreList = res.data.data;
        
      });

      // MembertDetail(parmes).then(res=>{
      //       this.memberinfo=res.data.data

      //   })
    },
    delMerberFun(memberStore){
      // 0待提交、1.提交审核、2.初审驳回、3.初审通过，复审中、4.复审通过、5.复审不通过、6.资质过期',
// 1.3 “审核中不可删除”     4.6 “已认证不可删除”
      // console.log(memberStore.auditStatus)
      if(memberStore.auditStatus==1 || memberStore.auditStatus==3){
        this.$message({
              message: '审核中不可删除',
              type: "warning",
            });
             return false
      }else if(memberStore.auditStatus==4 || memberStore.auditStatus==6){
        this.$message({
              message: '已认证不可删除',
              type: "warning",
            });
            return false
      }
      let parme={
        id:memberStore.id
      }
        delMember(parme).then(res=>{
          console.log(res)
          if(res.data.code==200){
             this.$message({
                message: res.data.msg,
                type: 'success'
              });
              this.reload();
          }else{
            this.$message({
              message: res.data.msg,
              type: "warning",
            });
        }
        })
    },
 switchFun(memberStore){
   console.log(memberStore.priceSchemeId)
  //  priceSchemeId”：==null表示未设置价格方案，！=null表示已经设置了价格方案
      if(memberStore.priceSchemeId==-1){
        this.$message({
              message: '该门店平台资料需补充完整，请联系客服处理！',
              type: "warning",
            });
            return false
      }
      if(memberStore.disableStatus==1){
        this.$message({
              message: '该门店为锁定状态，不能切换',
              type: "warning",
            });
            return false
      }
        changeMemberStore(memberStore.id).then(data=>{
            if(data.data.code==200){
                localStorage.setItem("memberName",data.data.data.enterpriseName);
                let token =localStorage.getItem('refresh_token')
                RefreshLogin(token).then(data=>{
                    console.log('66666',data.data)
                    setToken("bearer " + data.data.access_token);
                    setLocalToken("bearer " + data.data.access_token);
                    Cookies.set('memberName', data.data.nick_name);
                    localStorage.setItem("refresh_token", data.data.refresh_token)
                    let domainStr=document.domain.split('.').slice(-2).join('.')
                    Cookies.set('refresh_token',data.data.refresh_token,{path:'/',domain:domainStr});
                    // 缓存  会员等级与id及店铺id
                    const memberData = {
                      memberLevelId: data.data.member_level_id,
                      memberId: data.data.member_id,
                      memberStoreId: data.data.member_store_id,
                    };
                    sessionStorage.setItem("memberData", Qs.stringify(memberData));
                    // this.memberName(localStorage.getItem("memberName"));
                    this.$message.success("切换门店成功")
                    this.$router.push({ path: "/home" });
                })
            }else{
                 this.$message.error("切换门店失败")
            }
          
        })
    }
    
  },
  created() {
    this.getMembertDetail();
  },
  components: {
    TitleHeader,
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.NowBtn{
  color: #669eff;
  font-weight: bold;
}
.el-table {
  margin-top: 20px;
}
/deep/.cell{
  font-size: 12px;
}
.Btnbox{
  width:60px;
    text-align: center;
    height: 20px;
    padding: 0 5px;
    line-height: 19px;
    
    font-size: 12px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
    display: inline-block;
}
.lockBtn{
  background-color: #999999;
    border-color: #999999;
    border-width: 1px;
    color: #000;
}
.pastBtn{
  background-color: #8f8e92;
    border-color: #8f8e92;
    border-width: 1px;
    color: #000;
}
.unauthorizedBtn{
  background-color: #ffd303;
    border-color: #ffd303;
    border-width: 1px;
    color: #000;
}
</style>
